.site-form{
    .panel{
        box-shadow: none;
    }
    .panel-default{
        border: 0;
        margin-bottom: 0;
        &>.panel-heading{
            background-image: none;
            border: 0;
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
            position: relative;
            font-family: "HelveticaNeueCyr";
            font-weight: 500;
            &:before{
                content: "";
                width: 100%;
                height: 6px;
                background-color: #db6379;
                position: absolute;
                z-index: 0;
                left: 0;
                top: 50%;
                margin-top: - 3px;
            }
            .panel-title{
                color: #689af8;
                text-transform: uppercase;
                @include responsive-type(20px, 30px);
                display: inline-block;
                background-color: #fff;
                position: relative;
                z-index: 1;
                padding-right: 20px;
            }
        }
        &:nth-child(4){
            .form-group:not(.captcha){
                position: relative;
                height: 34px;
                input[type='text']{
                    position: absolute;
                    width: 100%;
                    height: 34px;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    border-color: #4f536d;
                    &:focus
                    {
                        background-color: #fff;
                    }
                }
                .is-error:not(.form-control){
                    position: relative;
                     z-index: -100;
                }
                .form-control.is-error{
                    border-color: red;
                }
            }

            label{
                color: #bdbec8;
                font-weight: normal;
                display: inline-block;
                height: 34px;
                line-height: 34px;
                padding-left: 15px;
            }
            .panel-body{
                padding-left: 0;
                padding-right: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                &:before{
                    content: none;
                }

                .form-group{
                    width: 100%;
                    max-width: 47%;
                    font-family: "HelveticaNeueCyr";
                }
            }
        }
        &:nth-child(8){

            .panel-body{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .form-group{
                    width: 100%;

                    &>label{
                        width: 100%;
                        color: #4f536d;
                        font-weight: 500;
                        font-size: 21px;
                        margin-bottom: 10px;
                    }
                }
                .form-group_hostel_early_check_in{
                    order: 1;

                    label{
                        font-size: 16px;
                    }
                }
                .form-group_hostel_start_date{
                    max-width: 47%;
                    order: 2;
                }
                .form-group_hostel_end_date{
                    max-width: 47%;
                    order: 3;
                }
                .form-group_hostel{
                    width: 47%;
                    order: 4;
                }
                .form-group_hostel_room{
                    width: 47%;
                    order: 6;
                }
                .form-group_hostel_end_time{
                    order: 5;
                    width: 47%;

                    .form-checkbox{
                        margin-bottom: 0;

                        &>label{
                            margin-bottom: 0;
                        }
                    }
                }
                .form-group_hostel_payment{
                    order: 7;
                }
            }
        }
    }
    .panel-body{
        padding: 30px 0 20px;
    }

    .form-group_forms_of_participation{
        &>label{
            display: none;
        }
    }
    .form-checkbox-button{
        width: 1.25em;
        height: 1.25em;
        vertical-align: middle;
        border: 1px solid currentColor;
    }
    .form-checkbox-button:before{
        top: .9em;
        left: 0.6em;
        background-color: #689af8;
    }
    .form-checkbox-button:after {
        top: .45em;
        left: .15em;
        background-color: #689af8;
    }
    .form-checkbox-field:checked~.form-checkbox-label__description,
    .form-checkbox-field:checked~.form-checkbox-label__name {
        color: #689af8;
    }
    .form-checkbox-label__description,
    .form-checkbox-label__name{
        font-weight: 500;
        color: #9298bf;
        padding-left: 5px;
        display: inline-block;
        vertical-align: middle;
    }
    .form-radio-button:after,
    .form-radio-button:before{
        width: 1.25em;
        height: 1.25em;
    }
    .form-radio-button{
        margin-top: -2px;
    }
    .form-group_hostel_payment,
    .form-group_courses_payment,
    .form-group_practical_courses,
    .form-group_payment,
    .form-group_options_for_participation,
    .form-group_forms_of_participation{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;

        &>label{
            width: 100%;
            color: #4f536d;
            font-weight: 500;
            font-size: 21px;
            margin-bottom: 10px;
        }

        .form-checkbox{
            width: 100%;
            max-width: 47%;
            margin-bottom: 5px;
        }
    }
    .on-line_reg{
        border: 0;
        margin-top: 0;
    }
    .super_batton{
        background-color: #689af8;
        text-transform: uppercase;
        display: block;
        border: 0;
        text-align: center;
        color: #fff;
        text-decoration: none;font-size: 18px;
        padding: 15px 0;
        margin: 0;
        width: 280px;
        i {
            background-color: transparent;
            color: #fff;
            border-radius: 0;
            padding: 0;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .capch_area{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
    @media screen  and (max-width: 600px){
        .form-group,
        .form-checkbox{
            max-width: 100% !important;
        }
        .super_batton{
            margin-top: 25px;
        }
        .panel-default:nth-child(8) .panel-body{
            .form-group_hostel_end_date{
                order: 10;
            }
            .form-group_hostel_end_time{
                order: 11;
            }
            .form-group_hostel{
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

img {
	object-fit: cover;
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.site-form {
    width: 100%;
    text-align: left;
    font-size: 16px;
    label {
        &.is-error {
            display: block;
            font-size: .85em;
            color: #dc3545;
            margin: 0;
            margin-top: 5px;
        }
    }
}

// .form-group_practical_courses {
//     display: none;
//     &.with-content {
//         display: block;
//     }
// }

.form-control {
    border-radius: 0;
    &.is-error {
        border-color: #dc3545;
        &:focus {
            box-shadow: inset 0 1px 1px rgba(#000, .075), 0 0 8px rgba(#dc3545, .6);
        }
    }
    &.is-valid {
        border-color: #28a745;
        &:focus {
            box-shadow: inset 0 1px 1px rgba(#000, .075), 0 0 8px rgba(#28a745, .6);
        }
    }
}

.form-checkbox-label {
    position: relative;
    cursor: pointer;
    padding-left: 1.5em;
    padding-top: .125em;
    text-align: left;
    color: #333;
    display: block;
    margin-bottom: .5em;
    font-weight: 400;
    input {
        width: auto;
        opacity: .0001;
        position: absolute;
        left: .25em;
        top: .25em;
        margin: 0;
        padding: 0;
    }
}

.form-checkbox-button {
    position: absolute;
    user-select: none;
    display: block;
    color: #999;
    left: 0;
    top: .25em;
    width: 1em;
    height: 1em;
    z-index: 0;
    border: .125em solid currentColor;
    border-radius: .0625em;
    transition: color .28s ease;
    will-change: color;
    &:before,
    &:after {
        position: absolute;
        height: 0;
        width: .2em;
        background-color: #337ab7;
        display: block;
        transform-origin: left top;
        border-radius: .25em;
        content: "";
        transition: opacity .28s ease,
        height 0s linear .28s;
        opacity: 0;
        will-change: opacity, height;
    }
    &:before {
        top: .65em;
        left: .38em;
        transform: rotate(-135deg);
        box-shadow: 0 0 0 .0625em #fff;
    }
    &:after {
        top: .3em;
        left: 0;
        transform: rotate(-45deg);
    }
}

.form-radio-button {
    position: absolute;
    left: 0;
    cursor: pointer;
    display: block;
    user-select: none;
    color: #999;
    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        margin: .25em;
        width: 1em;
        height: 1em;
        transition: transform 0.28s ease, color 0.28s ease;
        border-radius: 50%;
        border: .125em solid currentColor;
        will-change: transform, color;
    }
    &:after {
        transform: scale(0);
        background-color: #337ab7;
        border-color: #337ab7;
    }
}

.form-checkbox-field {
    &:checked {
        ~ .form-checkbox-button {
            color: #337ab7;
            &:before,
            &:after {
                opacity: 1;
                transition: height .28s ease;
            }
            &:before {
                height: 1.2em;
                transition-delay: .28s;
            }
            &:after {
                height: .5em;
            }
        }
        ~ .form-radio-button {
            &:before {
                color: #337ab7;
            }
            &:after {
                transform: scale(.5);
            }
        }
    }
}
